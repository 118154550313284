<template>
  <div class="header"
       ref="header"
       :style="{opacity: opactive}">
    <div class="header-logo">
      <div class="header-logo-img">
        <img src="@/assets/imgs/logo.png">
      </div>
      <div class="header-logo-data">
        <p>车商大师</p>
        <p>MASTER CAR DEALER </p>
      </div>
    </div>
    <div class="header-nav">
      <div v-for="(item,index) in headerArr"
           :key="index"
           @click="handleClickHeaderItem(index)"
           :class="{active: currentIndex === index}">{{item}}</div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      headerArr: ['首页', '发展历程', '关于我们', '招贤纳士'],
      currentIndex: 0
    }
  },
  computed: {
    ...mapState(['opactive'])
  },
  methods: {
    handleClickHeaderItem (index) {
      this.currentIndex = index
      switch (index) {
        case 0:
          this.$router.push('/')
          break
        case 1:
          this.$router.push('/state')
          break
        case 2:
          this.$router.push('/about')
          break
        case 3:
          this.$router.push('/recruitment')
          break
      }
    },
    _setCurrentIndex () {
      const url = window.location.href
      if (url.indexOf('about') > 0) {
        this.currentIndex = 2
      } else if (url.indexOf('state') > 0) {
        this.currentIndex = 1
      } else if (url.indexOf('recruitment') > 0) {
        this.currentIndex = 3
      } else {
        this.currentIndex = 0
      }
    }
  },
  mounted () {
    this._setCurrentIndex()
  }
}
</script>
<style scoped lang = "scss">
.header {
  height: 62px;
  background: #000;
  opacity: 0.5;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 1000;
  &-logo {
    display: flex;
    margin-left: 113px;
    align-items: center;
    &-img {
      width: 29px;
      height: 29px;
      background: #04b970;
      margin-right: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    &-data {
      color: #fff;
      p:nth-child(1) {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 25px;
      }
      p:nth-child(2) {
        font-size: 7px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 10px;
        -webkit-text-stroke: 1px #979797;
        line-height: 10px;
      }
    }
  }
  &-nav {
    margin-left: 318px;
    margin-right: 113px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    flex: 1;
    display: flex;
    justify-content: space-between;
    div {
      padding: 6px 15px;
    }
    div:hover {
      cursor: pointer;
    }
  }
}
.active {
  color: #04b970;
  border-bottom: 1px solid #04b970;
}
</style>
