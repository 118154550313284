<template>
  <div id="app">
    <topHeader></topHeader>
    <router-view />
    <btmFooter></btmFooter>
  </div>
</template>
<script>
import topHeader from '@/components/header'
import btmFooter from '@/components/footer'
export default {
  components: {
    topHeader,
    btmFooter
  },
  mounted () {

  }
}
</script>
<style>
</style>
