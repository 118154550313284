<template>
  <div class="box">
    <div class="banner"
         ref="banner">
      <img src="https://image.csdashi.cn/static/banner.png">
      <p class="title1">专注全国二手车商车源服务和交易配套服务</p>
      <p class="title2">推动产业重构，用科技创造二手车高效流通渠道</p>
      <transition name="icon"
                  mode="out-in">
        <div class="ewm"
             v-if="currentIconIndex === 0"
             :key="1">
          <img src="../assets/imgs/app.png"
               alt="">
        </div>
        <div class="ewm"
             v-if="currentIconIndex === 1"
             :key="2">
          <img src="../assets/imgs/app.png"
               alt="">
        </div>
      </transition>
      <div class="ewm_btm">
        <div @mouseenter="mousemove(0)">
          <i class="iconfont">&#xe629;</i>
          <span>iOS</span>
        </div>
        <div @mouseenter="mousemove(1)">
          <i class="iconfont">&#xeaa3;</i>
          <span>Android</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="serve-module">
        <h2>车商服务</h2>
        <h3>Service</h3>
        <div class="module">
          <div class="internet-module">
            <div class="internet-module-img">
              <img src="@/assets/imgs/phonex.png">
            </div>
            <div class="internet-modult-introduce">
              <h4>内网车源</h4>
              <p>汇聚全国实名认证二手车商，一手真实车源丰富可靠。智能风控审核，即时下架解决车源信息失真。一秒搜索实时掌握全城库存，线下自由交易。专业客服团队一对一帮卖，精准匹配交易双方，高效达成合作。</p>
            </div>
          </div>
          <div class="dealer-store">
            <div class="internet-module-img">
              <img src="@/assets/imgs/phonex.png">
            </div>
            <div class="internet-modult-introduce">
              <h4>车商店铺</h4>
              <p>专为车商打造，库存车辆智能管理。创建店铺联盟，全城同行帮卖，缩小库存周期。提供多种营销方案，支持一键分享朋友圈，满足车商不同场景需求。</p>
            </div>
          </div>
        </div>
      </div>
      <div class="taskIntroduced">
        <h2>业务介绍</h2>
        <h3>INTRODUCT</h3>
        <div class="task-module">
          <div class="task-module-item"
               @click="handleClick(0)">
            <img src="@/assets/imgs/pc_jc.png"
                 v-show="currentIndex != 0">
            <img src="@/assets/imgs/ccjc-ac.png"
                 alt=""
                 v-show="currentIndex == 0">
            <p>车辆检测</p>
          </div>
          <div class="task-module-item"
               @click="handleClick(1)">
            <img src="@/assets/imgs/pc_bx.png"
                 v-show="currentIndex != 1">
            <img src="@/assets/imgs/ycbx-ac.png"
                 alt=""
                 v-show="currentIndex == 1">
            <p>延长保修</p>
          </div>
          <div class="task-module-item"
               @click="handleClick(2)">
            <img src="@/assets/imgs/pc_wl.png"
                 v-show="currentIndex != 2">
            <img src="@/assets/imgs/jjwl-ac.png"
                 alt=""
                 v-show="currentIndex == 2">
            <p>竞价物流</p>
          </div>
          <div class="task-module-item"
               @click="handleClick(3)">
            <img src="@/assets/imgs/pc_pm.png"
                 v-show="currentIndex !== 3">
            <img src="@/assets/imgs/xxpm-ac.png"
                 alt=""
                 v-show="currentIndex == 3">
            <p>线下组织拍卖</p>
          </div>
          <div class="task-module-item"
               @click="handleClick(4)">
            <img src="@/assets/imgs/pc_gz.png"
                 v-show="currentIndex !== 4">
            <img src="@/assets/imgs/wtpm-ac.png"
                 alt=""
                 v-show="currentIndex == 4">
            <p>资源车委托拍卖</p>
          </div>
        </div>
        <div class="task-module-toast">
          <transition name="fade"
                      mode="out-in">
            <div class="data"
                 v-if="currentIndex == 0"
                 key="0">
              <p>
                <span></span>
                <span>支持全国车辆检查</span>
              </p>
              <p>
                <span></span>
                <span>出具专业检查报告</span>
              </p>
              <p>
                <span></span>
                <span>展示车辆实际状况</span>
              </p>
              <p>
                <span></span>
                <span>规避交易风险</span>
              </p>
            </div>
            <div class="data"
                 v-if="currentIndex == 1"
                 key="1">
              <p>
                <span></span>
                <span>1年3万公里保障</span>
              </p>
              <p>
                <span></span>
                <span>16-26大系统保障</span>
              </p>
              <p>
                <span></span>
                <span>针对不同车辆车况</span>
              </p>
              <p>
                <span></span>
                <span>多种套餐随意搭配</span>
              </p>
            </div>
            <div class="data"
                 v-if="currentIndex == 2"
                 key="2">
              <p>
                <span></span>
                <span>二手车全国物流服务</span>
              </p>
              <p>
                <span></span>
                <span>轻松完成交易车辆的远程运输及交付</span>
              </p>
              <p>
                <span></span>
                <span>实现二手车全国跨区域流通</span>
              </p>
            </div>
            <div class="data"
                 v-if="currentIndex === 3"
                 key="100">
              <p>
                <span></span>
                <span>车商大师拥有强大的客户体系</span>
              </p>
              <p>
                <span></span>
                <span>合法的拍卖资质</span>
              </p>
              <p>
                <span></span>
                <span>完善的拍卖系统</span>
              </p>
              <p>
                <span></span>
                <span>帮助客户举办线下拍卖</span>
              </p>
            </div>
            <div class="data"
                 v-if="currentIndex == 4"
                 key="4">
              <p>
                <span></span>
                <span>为批量新车、二手车、库存车企业提供销售方案</span>
              </p>
              <p>
                <span></span>
                <span>快速帮助客户以合理充分的价格处置车辆</span>
              </p>
            </div>
          </transition>
          <div class="img">
            <img src="https://image.csdashi.cn/static/website/d/pc_jc1.png">
          </div>
        </div>
      </div>
      <div class="products">
        <h2>产品功能介绍</h2>
        <h3>FUNCTION</h3>
        <div class="products-wrap">
          <div class="products-wrap-item">
            <img src="@/assets/imgs/history.png">
            <p>历史车况</p>
            <p>专注二手车历史车况查询功能，覆盖市场大部分主流品牌，4S维保、碰撞出险记录，10秒出结果。</p>
          </div>
          <div class="products-wrap-item">
            <img src="@/assets/imgs/jzgz.png">
            <p>精准估值</p>
            <p>基于真实交易数据，进行精准的车辆价格评估。实时收集反馈信息，利用人工智能技术进行精准估价。</p>
          </div>
          <div class="products-wrap-item">
            <img src="@/assets/imgs/clsb.png">
            <p>车型识别</p>
            <p>识别车辆详细信息及配置，从新车指导价到生产日期、车辆版本、配置详情一键查询。</p>
          </div>
          <div class="products-wrap-item">
            <img src="@/assets/imgs/bjgn.png">
            <p>便捷功能</p>
            <p>车商实用小功能，全国限迁查询、车辆配件查询、智能短视频制作，一站式实用增值服务。</p>
          </div>
          <div class="products-wrap-item">
            <img src="@/assets/imgs/dbf.png">
            <p>担保付</p>
            <p>支持在线担保付，平台资金监管，保障交易资金安全，公平公正，维护买卖双方的合法权益。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      dataList: ['支持全国车辆检查'],
      currentIndex: 0,
      currentIconIndex: 0
    }
  },
  mounted () {
    this._setHeaderBackground()
    this.getBannerHeight()
  },
  methods: {
    ...mapMutations(['SET_OPSCTIVE']),
    _setHeaderBackground () {
      window.addEventListener('scroll', (value) => {
        const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
        if (scrollTop > this.banner - 62) {
          this.SET_OPSCTIVE(1)
        } else {
          this.SET_OPSCTIVE(0.5)
        }
      })
    },
    getBannerHeight () {
      this.banner = this.$refs.banner.getBoundingClientRect().height
    },
    handleClick (index) {
      this.currentIndex = index
    },
    mousemove (value) {
      this.currentIconIndex = value
    }
  }
}
</script>
<style lang='scss' scoped>
.ewm {
  width: 118px;
  height: 118px;
  background: #ffffff;
  border-radius: 5px;
  position: absolute;
  top: 322px;
  left: 416px;
  img {
    width: 100%;
    height: 100%;
  }
}
.ewm_btm {
  display: flex;
  position: absolute;
  top: 482px;
  left: 416px;

  div {
    width: 183px;
    height: 51px;
    border-radius: 5px;
    border: 1px solid #979797;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin-right: 17px;
    background: #ffffff;
    i {
      font-size: 21px;
      color: #000000;
      margin-right: 15px;
    }
  }
  div:hover {
    background: #04b970;
    color: #fff;
    i {
      color: #fff;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  transform: translate3d(-300px, 0, 0);
}
.fade-enter-to,
.fade-leave {
  transform: translate3d(0, 0, 0);
}
.icon-enter-active,
.icon-leave-active {
  transition: all 0.5s;
}
.icon-enter-to,
.icon-leave {
  transform: translate3d(0px, 0, 0);
  opacity: 1;
}
.icon-leave-to,
.icon-enter {
  transform: translate3d(-100px, 0, 0);
  opacity: 0;
}
.box {
  .content {
    background: #fff;
  }
  .banner {
    height: 608px;
    position: relative;
    img {
      height: 100%;
      width: 100%;
    }
    .title1 {
      position: absolute;
      top: 0px;
      font-size: 39px;
      color: #fff;
      text-align: center;
      width: 100%;
      margin-top: 144px;
    }
    .title2 {
      position: absolute;
      font-size: 39px;
      color: #fff;
      top: 0px;
      text-align: center;
      width: 100%;
      margin-top: 206px;
    }
    video {
      position: fixed;
      top: 0px;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto; //尺寸保持原视频大小
      z-index: -100; //z轴定位，小于0即可
      -webkit-filter: grayscale(
        20%
      ); //添加灰度蒙版，如果设定为100%则视频显示为黑白
    }
  }
  .module {
    display: flex;
  }
  .serve-module {
    overflow: hidden;
    height: 304px;
    background: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: center;
      margin-top: 32px;
      line-height: 16px;
    }
    h3 {
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #cccccc;
      line-height: 16px;
      margin-top: 9px;
    }
    .internet-module {
      width: 421px;
      height: 150px;
      background: #ffffff;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16);
      margin-top: 45px;
      display: flex;
      transition: all 0.5s;
      -moz-transition: all 0.5s; /* Firefox 4 */
      -webkit-transition: all 0.5s; /* Safari and Chrome */
      -o-transition: all 0.5s; /* Opera */
      .internet-module-img {
        width: 115px;
        height: 135px;
        background: #397c61;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16);
        flex-shrink: 0;
        margin-top: 8px;
        margin-left: 7px;
        position: relative;
        img {
          width: 102px;
          height: 100%;
          position: absolute;
          top: 5px;
        }
      }
      .internet-modult-introduce {
        h4 {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: center;
          margin-top: 22px;
          line-height: 12px;
        }
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          margin-top: 19px;
          padding: 0px 33px;
        }
      }
    }
    .internet-module:hover {
      transform: translateY(-15px);
      transition: transform 0.3;
    }
    .dealer-store {
      width: 421px;
      height: 150px;
      background: #ffffff;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16);
      margin-top: 45px;
      margin-left: 21px;
      display: flex;
      transition: all 0.5s;
      -moz-transition: all 0.5s; /* Firefox 4 */
      -webkit-transition: all 0.5s; /* Safari and Chrome */
      -o-transition: all 0.5s; /* Opera */
      .internet-module-img {
        width: 115px;
        height: 135px;
        background: #397c61;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16);
        flex-shrink: 0;
        margin-top: 8px;
        margin-left: 7px;
        position: relative;
        img {
          width: 102px;
          height: 100%;
          position: absolute;
          top: 5px;
        }
      }
      .internet-modult-introduce {
        h4 {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: center;
          margin-top: 22px;
          line-height: 12px;
        }
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          margin-top: 19px;
          padding: 0px 33px;
        }
      }
    }
    .dealer-store:hover {
      transform: translateY(-15px);
    }
  }
  .taskIntroduced {
    h2 {
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 16px;
      margin-top: 32px;
    }
    h3 {
      text-align: center;
      margin-top: 9px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #cccccc;
      line-height: 16px;
    }
    .task-module {
      padding: 0px 116px;
      display: flex;
      justify-content: space-between;
      margin-top: 45px;
      .task-module-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 47px;
          height: 47px;
        }
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
        }
      }
    }
    .task-module-toast {
      height: 343px;
      background: #397c61;
      margin-top: 30px;
      position: relative;
      overflow: hidden;
      .img {
        overflow: hidden;
        width: 500px;
        height: 315px;
      }
      img {
        width: 502px;
        position: absolute;
        top: 30px;
        right: 81px;
        transform-origin: center;
        perspective: 800px;
        transform: rotateX(0deg) rotateY(-5deg) rotateZ(0deg);
        transition: all 10s;
      }
      img:hover {
        transform: scale(1.2) translateX(-30px);
      }
      .data {
        margin-top: 91px;
        margin-left: 118px;
        width: 400px;
      }
      p {
        span:nth-child(1) {
          display: inline-block;
          width: 9px;
          height: 9px;
          background: #04b970;
          border-radius: 50%;
          margin-right: 12px;
        }
        span:nth-child(2) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 41px;
        }
      }
    }
  }
  .products {
    padding-bottom: 60px;
    h2 {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 16px;
      text-align: center;
      margin-top: 32px;
    }
    h3 {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #cccccc;
      line-height: 16px;
      text-align: center;
      margin-top: 9px;
    }
    .products-wrap {
      padding: 0px 130px;
      display: flex;
      justify-content: space-between;
      margin-top: 39px;
      .products-wrap-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.5s;
        padding-bottom: 20px;
        img {
          width: 102px;
          height: 87px;
        }
        p:nth-child(2) {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 14px;
        }
        p:nth-child(2)::after {
          display: block;
          content: "";
          width: 25px;
          height: 1px;
          background: #04b970;
          margin-top: 15px;
          margin-left: 15px;
        }
        p:nth-child(3) {
          margin-top: 27px;
          width: 137px;
          font-size: 9px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
        }
      }
      .products-wrap-item:hover {
        transform: translateY(-10px);
        box-shadow: 10px 10px 10px 1px #f1f1f1;
      }
    }
  }
  .footer {
    overflow: hidden;
    height: 156px;
    background: #000000;
    display: flex;
    justify-content: space-between;
    padding: 0px 113px;
    .copyright {
      p:nth-child(1) {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 14px;
        margin-top: 56px;
      }
      p:nth-child(2) {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 14px;
        margin-top: 10px;
      }
    }
    .ewm {
      display: flex;
      margin-top: 25px;
      .ewm-item {
        margin-right: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 68px;
          height: 68px;
          background: pink;
        }
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 12px;
          margin-top: 7px;
        }
      }
    }
  }
}
</style>
