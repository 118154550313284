import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import state from '../views/state.vue'
import about from '../views/about.vue'
import recruitment from '../views/recruitment.vue'
Vue.use(VueRouter)
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    console.log(savedPosition)
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}
const routes = [
  {
    path: '/',
    component: index
  },
  {
    path: '/state',
    component: state
  },
  {
    path: '/about',
    component: about
  },
  {
    path: '/recruitment',
    component: recruitment
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})
export default router
