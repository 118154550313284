<template>
  <div class="state">
    <div class="banner"
         ref="banner">
      <img src="https://image.csdashi.cn/static/website/d/background.png"
           alt="">
      <p>故事很长，精彩待续…</p>
    </div>
    <div class="content">
      <img src="https://image.csdashi.cn/static/website/d/daolu.png"
           alt=""
           class="bga">
      <div class="data_wrap">
        <img src="https://image.csdashi.cn/static/website/d/state_bga.png"
             alt=""
             class="bga_data">
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
    }
  },
  mounted () {
    this._setHeaderBackground()
    this.getBannerHeight()
  },
  methods: {
    ...mapMutations(['SET_OPSCTIVE']),
    _setHeaderBackground () {
      window.addEventListener('scroll', () => {
        const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
        if (scrollTop > this.banner - 62) {
          this.SET_OPSCTIVE(1)
        } else {
          this.SET_OPSCTIVE(0.5)
        }
      })
    },
    getBannerHeight () {
      this.banner = this.$refs.banner.getBoundingClientRect().height
    }
  }
}
</script>
<style scoped lang = 'scss'>
.banner {
  height: 461px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
  p {
    text-align: center;
    height: 100%;
    line-height: 461px;
    color: #fff;
    font-size: 56px;
    position: absolute;
    width: 100%;
  }
}
.content {
  height: 830px;
  position: relative;
  .bga {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
  .data_wrap {
    padding: 58px;
    position: absolute;
    width: 100%;
    height: 717px;
    box-sizing: border-box;
    left: -200px;
  }
  .bga_data {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
  }
}
</style>
